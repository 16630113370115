import React, { useEffect, useMemo } from "react";
import { graphql, Link } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import AOS from "aos";
import "aos/dist/aos.css";

const Contact = styled.div`
  display: flex;
  flex-direction: row;

  position: fixed;
  right: 30px;
  bottom: 120px;

  transform: rotate(90deg);
  transform-origin: right;

  & p {
    margin: 0;

    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.05em;
  }

  & p:first-of-type {
    margin-right: 20px;
  }
`;

const ContactText = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;

  width: 40%;

  & p {
    margin: 0;

    font-size: 15px;
    line-height: 140%;
    letter-spacing: 0.05em;
  }
`;

const Title = styled.div`
  margin-top: 10px;

  & h2 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
  }
`;

const AllProjects = styled.div`
  position: relative;
  background: #fff;

  overflow-x: hidden;

  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-gap: 15px;
  // grid-auto-flow: dense;

  margin: 100px 0 150px 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(7, 1fr);
  }

  & [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1;
  }

  & [data-aos="fade-right"] {
    opacity: 1;
    transform: translate3d(-100vw, 0, 0);
  }

  & [data-aos="fade-left"] {
    opacity: 1;
    transform: translate3d(100vw, 0, 0);
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

const ProjectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  position: relative;
  width: 100%;

  & > div {
    grid-column: ${props => props.gridColumn};
    position: relative;
    width: 80%;
  }

  &:nth-child(8n + 1) {
    grid-column: 2 / 7;
  }

  &:nth-child(8n + 2) {
    grid-column: 9 / 14;
    margin-top: 100px;

    & > div {
      margin: 0 auto;
    }
  }

  &:nth-child(8n + 3) {
    grid-column: 4 / 9;
    // margin-top: 100px;
    margin-top: 100px;
  }

  &:nth-child(8n + 4) {
    grid-column: 9 / 14;
    margin-top: 300px;

    & > div {
      margin: 0 0 0 auto;
    }
  }

  &:nth-child(8n + 5) {
    grid-column: 2 / 7;
    margin-top: 100px;
  }

  &:nth-child(8n + 6) {
    grid-column: 8 / 13;
    // grid-column: 10 / 15;
    // margin-top: 200px;
    margin-top: 300px;
  }

  &:nth-child(8n + 7) {
    // margin-top: 60px;
    margin-top: 100px;
    grid-column: 3 / 8;
  }

  &:nth-child(8n + 8) {
    grid-column: 9 / 14;
    // grid-column: 8 / 12;
    margin-top: 300px;
    margin-bottom: 50px;

    & > div {
      margin: 0 0 0 auto;
    }
  }

  @media (max-width: 767px) {
    & > div {
      grid-column: ${props => props.gridColumn};
      position: relative;
      width: 100%;
    }

    &:nth-child(8n + 1),
    &:nth-child(8n + 2),
    &:nth-child(8n + 3),
    &:nth-child(8n + 4),
    &:nth-child(8n + 5),
    &:nth-child(8n + 6),
    &:nth-child(8n + 7),
    &:nth-child(8n + 8) {
      margin: 25px 0 50px 0;
    }

    &:nth-child(8n + 1) {
      grid-column: 2 / 6;
    }

    &:nth-child(8n + 2) {
      grid-column: 3 / 7;
    }

    &:nth-child(8n + 3) {
      grid-column: 2 / 6;
    }

    &:nth-child(8n + 4) {
      grid-column: 3 / 7;
    }

    &:nth-child(8n + 5) {
      grid-column: 2 / 6;
    }

    &:nth-child(8n + 6) {
      grid-column: 3 / 7;
    }

    &:nth-child(8n + 7) {
      grid-column: 2 / 6;
    }

    &:nth-child(8n + 8) {
      grid-column: 3 / 7;
    }
  }
`;

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${props => props.aspectRatio + `%`};
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
  }
`;

const IS_BROWSER = typeof window !== "undefined";
const Index = ({ location, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;
    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  useEffect(() => {
    document.body.style = "";

    const triggerAOS = setTimeout(() => {
      AOS.init();
      setTimeout(AOS.refreshHard, 150);
    }, 2500);

    return () => clearTimeout(triggerAOS);
  }, []);

  let projects = data.prismicHome.data.projects.map((project, index) => {
    if (project.project.document.data.homepage_thumbnail_select === "Image") {
      let imageWidth =
        project.project.document.data.homepage_image.dimensions.width;
      let imageHeight =
        project.project.document.data.homepage_image.dimensions.height;

      return (
        <ProjectWrapper
          gridColumn={imageWidth > imageHeight ? "1/4" : "1/3"}
          data-aos={
            (index === 3 && "fade-left") ||
            (index === 6 && "fade-right") ||
            (index === 9 && "fade-left") ||
            (index === 12 && "fade-right") ||
            (index === 15 && "fade-left") ||
            (index === 18 && "fade-right") ||
            (index === 21 && "fade-left") ||
            (index === 24 && "fade-right")
          }
          data-aos-duration="2500"
          key={`${project.project.document.uid}_${index}`}
        >
          <div>
            <Link to={`/projects/${project.project.document.uid}`}>
              <img
                src={project.project.document.data.homepage_image.url}
                alt={project.project.document.data.homepage_image.alt}
              />
              <Title
                dangerouslySetInnerHTML={{
                  __html: project.project.document.data.title.html,
                }}
              />
            </Link>
          </div>
        </ProjectWrapper>
      );
    } else if (
      project.project.document.data.homepage_thumbnail_select === "Video"
    ) {
      let videoWidth = project.project.document.data.homepage_video.width;
      let videoHeight = project.project.document.data.homepage_video.height;

      return (
        <React.Fragment key={`${project.project.document.uid}_${index}`}>
          <Helmet>
            <link
              rel="canonical"
              href={`https://player.vimeo.com/video/${project.project.document.data.homepage_video.video_id}?background=1`}
              as="document"
            />
          </Helmet>
          <ProjectWrapper
            gridColumn={videoWidth > videoHeight ? "1/4" : "2/4"}
            data-aos={
              (index === 3 && "fade-left") ||
              (index === 6 && "fade-right") ||
              (index === 9 && "fade-left") ||
              (index === 12 && "fade-right") ||
              (index === 15 && "fade-left") ||
              (index === 18 && "fade-right") ||
              (index === 21 && "fade-left") ||
              (index === 24 && "fade-right")
            }
            data-aos-duration="2500"
          >
            <div>
              <Link to={`/projects/${project.project.document.uid}`}>
                <IframeContainer aspectRatio={(videoHeight / videoWidth) * 100}>
                  <iframe
                    src={`https://player.vimeo.com/video/${project.project.document.data.homepage_video.video_id}?background=1`}
                    width="640"
                    height="360"
                    frameBorder="0"
                    title={
                      project.project.document.data.homepage_video.video_id
                    }
                    allowFullScreen
                    playsInline
                    autoPlay
                    muted
                    preload="auto"
                  ></iframe>
                </IframeContainer>

                <Title
                  dangerouslySetInnerHTML={{
                    __html: project.project.document.data.title.html,
                  }}
                />
              </Link>
            </div>
          </ProjectWrapper>
        </React.Fragment>
      );
    }
  });

  return <AllProjects>{projects}</AllProjects>;
};

export default Index;

export const homeQuery = graphql`
  {
    prismicHome {
      data {
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                uid
                data {
                  title {
                    html
                    text
                  }
                  homepage_thumbnail_select
                  homepage_image {
                    url
                    alt
                    dimensions {
                      height
                      width
                    }
                  }
                  homepage_video {
                    width
                    height
                    video_id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
